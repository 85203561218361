import React from "react";
import { useState, useEffect } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderSix from "../component/header/HeaderSix";
import Footer from "../component/footer/Footer";
import TabTwo from "../elements/tab/TabTwo";
import ServiceTwo from "../elements/service/ServiceTwo";
import BlogContent from "../elements/blog/BlogContent";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BrandTwo from "../elements/BrandTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import FormStyle from "../elements/contact/FormStyle";
import {
  FaTwitter,
  FaTelegramPlane,
  FaDiscord,
  FaYoutube,
  FaGit,
  FaMedium,
  FaFileAlt,
} from "react-icons/fa";

const SocialShare = [
  {
    Social: <FaTelegramPlane />,
    link: "https://t.me/POWERCITYio/",
    text: "Telegram",
  },
  {
    Social: <FaTwitter />,
    link: "https://twitter.com/POWERCITYio/",
    text: "Twitter",
  },
  {
    Social: <FaYoutube />,
    link: "https://www.youtube.com/channel/UCKoFHpAen2bjhqp4Vzq6ZRQ",
    text: "YouTube",
  },
  {
    Social: <FaMedium />,
    link: "https://medium.com/@powercity",
    text: "Medium",
  },
  {
    Social: <FaFileAlt />,
    link: "https://docs.powercity.io/",
    text: "Documentation",
  },
];

const SlideList = [
  {
    textPosition: "text-left",
    category: "Welcome to Powercity",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];
const Main = () => {
  let title = "POWERCITY",
    description = "";
  const PostList = BlogContent.slice(0, 3);

  return (
    <div className="active-dark bg_color--9">
      <Helmet pageTitle="POWERCITY" />

      <HeaderSix />

      {/* Start Slider Area   */}
      <div id="home" className="fix">
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center"
              key={index}>
              <div id="video-container">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  poster="images/bg.jpg"
                  id="bgvidmain">
                  <source src="/assets/video/bgvid03.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      <h1 className="title">
                        Better Tools for&nbsp;
                        <br />
                        <TextLoop>
                          <span> a Better Blockchain</span>
                          <span> Cheaper Onboarding</span>
                          <span> Multi-Chain DeFi</span>
                          <span> Leveraging Your Crypto</span>
                          <span> PulseChain Adoption</span>
                          <span> Exploring the Metaverse</span>
                          <span> Bridging Blockchains</span>
                          <span> Compounding Yields</span>
                          <span> Utilizing NFT's</span>
                          <span> Building Communities</span>
                          <span> Censorship Free Crypto</span>
                          <span> Financial Independence</span>
                          <span> Simplifying DeFi</span>
                          <span> a Better Tomorrow</span>
                        </TextLoop>{" "}
                      </h1>
                      <p className="white">
                        <strong>POWERCITY</strong> is an ecosystem of projects
                        designed to improve PulseChain for the community.
                        <br className="respbreak" /> All connected through its
                        central CORE.
                      </p>
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn mt--30">
                          <a
                            className="btn-default btn-border btn-sm btn-opacity"
                            href={`${value.buttonLink}`}>
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="social-share-inner">
                        <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                          {SocialShare.map((val, i) => (
                            <li key={i}>
                              <a target="_blank" href={`${val.link}`}>
                                {val.Social}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area
             <div id="about" className="about-area ptb--120 bg_color--8 fix">
                    <About />
                </div>
              End About Area */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area pt--40 pb--70  bg_color--8">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35">
                <div className="col-lg-4">
                  <div className="thumbnail">
                    <video playsInline autoPlay muted loop id="bgvidabout">
                      <source
                        src="/assets/video/citytop.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <span className="subtitle">About</span>
                      <h2 className="title mb--20">{title}</h2>
                      <p className="description mt_dec--20">{description}</p>
                    </div>
                    <div className="row mt--40">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start Service Area  */}
      <div id="apps" className="fix">
        <div className="service-area ptb--40 bg_color--5">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start Portfolio Area */}
      <div id="nft" className="portfolio-area ptb--40 bg_color--8 fix">
        <div className="portfolio-sacousel-inner mb--55">
          <Portfolio />
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start CounterUp Area */}
      <div id="stats" className="rn-counterup-area ptb--120 bg_color--5 fix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">Current Numbers</span>
                <h2 className="title">POWERCITY</h2>
                <p className="description"></p>
              </div>
            </div>
          </div>
          <CounterOne />
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Testimonial Area */}
      <div id="team" className="rn-testimonial-area bg_color--8 ptb--40 fix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center mb--30">
                <span className="subtitle">
                  The rare people who don't know how to quit
                </span>
                <h2 className="title">POWERCITY Team</h2>
              </div>
            </div>
          </div>
          <Testimonial />
        </div>
      </div>
      {/* End Testimonial Area */}

      {/* Start Brand Area */}
      <div id="tech" className="rn-brand-area bg_color--5 ptb--40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center mb--30">
                <span className="subtitle">POWERCITY</span>
                <h2 className="title">Development Technologies</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      <FormStyle />

      <Footer />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default Main;
