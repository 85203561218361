import React, { Component } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];
class HeaderSix extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <header className="header-area formobile-menu header--fixed default-color">
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                <img
                  className="logo-1"
                  src="/assets/images/logo/logo-powercity.gif"
                  alt="Powercity"
                />
                <img
                  className="logo-2"
                  src="/assets/images/logo/pc-logo-icon.gif"
                  alt="Powercity"
                />
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <Scrollspy
                className="mainmenu"
                items={[
                  "home",
                  "about",
                  "apps",
                  "nft",
                  "team",
                  "sacrifice",
                  "docs",
                ]}
                currentClassName="is-current"
                offset={-200}>
                <li>
                  <ScrollLink to="home" smooth={true} offset={0} duration={500}>
                    Home
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="about"
                    smooth={true}
                    offset={0}
                    duration={500}>
                    About
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink to="apps" smooth={true} offset={0} duration={500}>
                    Apps
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink to="nft" smooth={true} offset={0} duration={500}>
                    NFT
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink to="team" smooth={true} offset={0} duration={500}>
                    Team
                  </ScrollLink>
                </li>
                <li>
                  <a target="_blank" href="https://docs.powercity.io/">
                    Docs
                  </a>
                </li>
              </Scrollspy>
            </nav>
            {/* <div className="header-btn">
                                <a className="btn-default size-md btn-border btn-opacity" target="_blank" href="https://powercity.io/corebuildv1">
                                    <span>Connect</span>
                                </a>
                            </div> */}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderSix;
